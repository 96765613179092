if(window.isLoggedIn && window.isSubscribed) {
    if (navigator.serviceWorker) {
        navigator.serviceWorker.register('/service-worker.js').then(reg => {
            navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
                serviceWorkerRegistration.pushManager
                    .subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: window.vapidPublicKey
                    }).then(sub => {
                    fetch('/subscriptions', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(sub)
                    }).then(res => {
                        if(res.status !== 200) {
                            const alert = document.querySelector('.warning-message')
                            alert.classList.remove('opacity-0');
                            alert.classList.add('opacity-100');
                            setTimeout(() => {
                                alert.classList.remove('opacity-100');
                                alert.classList.add('opacity-0');
                            }, 3000)
                        }
                    }).catch(function (err) {
                        console.error('Failed to subscribe:', err);
                    });
                })
            });
        })
    } else {
        console.error("Service worker is not supported in this browser")
    }
}

// Example: Load Rails libraries in Vite.

// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
